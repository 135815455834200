a[target='_blank']:after {
  content: '\1F5D7'
}

/*
  Hides Sitecore editor markup,
  if you run the app in connected mode while the Sitecore cookies
  are set to edit mode.
*/
.scChromeData, .scpm { display: none !important; }

/*
  Styles for default JSS error components
*/
.sc-jss-editing-error,
.sc-jss-placeholder-error {
  padding: 1em;
  background-color: lightyellow;
}
