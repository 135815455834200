@font-face {
  font-family: Proxima Nova;
  src: url('../assets/fonts/ProximaNova/proximanova-medium.woff2') format('woff2'),
    url('../assets/fonts/ProximaNova/proximanova-medium.woff') format('woff'),
    url('../assets/fonts/ProximaNova/proximanova-medium.ttf') format('ttf'),
    url('../assets/fonts/ProximaNova/proximanova-medium.otf') format('otf'),
    url('../assets/fonts/ProximaNova/proximanova-medium.eot') format('eot');
  font-display: swap;
}
@font-face {
  font-family: Proxima Nova SemiBold;
  src: url('../assets/fonts/ProximaNova/proximanova-semibold.woff2') format('woff2'),
    url('../assets/fonts/ProximaNova/proximanova-semibold.woff') format('woff'),
    url('../assets/fonts/ProximaNova/proximanova-semibold.ttf') format('ttf'),
    url('../assets/fonts/ProximaNova/proximanova-semibold.otf') format('otf'),
    url('../assets/fonts/ProximaNova/proximanova-semibold.eot') format('eot');
  font-display: swap;
}
@font-face {
  font-family: Proxima Nova Bold;
  src: url('../assets/fonts/ProximaNova/proximanova-bold.woff2') format('woff2'),
    url('../assets/fonts/ProximaNova/proximanova-bold.woff') format('woff'),
    url('../assets/fonts/ProximaNova/proximanova-bold.ttf') format('ttf'),
    url('../assets/fonts/ProximaNova/proximanova-bold.otf') format('otf'),
    url('../assets/fonts/ProximaNova/proximanova-bold.eot') format('eot');
  font-display: swap;
}
@font-face {
  font-family: Proxima Nova Regular;
  src: url('../assets/fonts/ProximaNova/proximanova-regular.woff2') format('woff2'),
    url('../assets/fonts/ProximaNova/proximanova-regular.woff') format('woff'),
    url('../assets/fonts/ProximaNova/proximanova-regular.ttf') format('ttf'),
    url('../assets/fonts/ProximaNova/proximanova-regular.otf') format('otf'),
    url('../assets/fonts/ProximaNova/proximanova-regular.eot') format('eot');
  font-display: swap;
}

body {
  background-color: #ffffff;
}

.container {
  min-height: calc(100vh - 7rem);
  padding-left: 0;
  padding-right: 0;
  background-color: #ffffff;
}

a[target='_blank']:after {
  content: none !important;
}

// Global overrides for Richtexts from Sitecore which are not wrapped in MUI Typography
body {
  font-family: 'Proxima Nova';
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
}

h1 {
  font-family: 'Proxima Nova';
  font-weight: 400;
  font-size: 3rem;
  line-height: 3.125rem;
}

h2 {
  font-family: 'Proxima Nova';
  font-weight: 700;
  font-size: 2.5rem;
  line-height: 3rem;
}

h3 {
  font-family: 'Proxima Nova';
  font-weight: 400;
  font-size: 2rem;
  line-height: 2.5rem;
}

h4 {
  font-family: 'Proxima Nova';
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 2rem;
}

h5 {
  font-family: 'Proxima Nova';
  font-weight: 400;
  font-size: 1.375rem;
  line-height: 1.875rem;
}

h6 {
  font-family: 'Proxima Nova';
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.25rem;
}

// Any footer link inside rich text will have a class .link--white
// For eg Copyright text
.link--white {
  color: #ffffff;
  text-decoration: none;
  &:hover {
    color: #ffffff;
    text-decoration: none;
    border-bottom: 1px dashed;
  }
}

// Handle responsive font sizes for Richtext headings
@media (max-width: 767px) {
  h1 {
    font-size: 2.5rem;
    line-height: 3rem;
  }

  h2 {
    font-size: 1.5rem;
    line-height: 1.875rem;
  }

  h3 {
    font-size: 1.25rem;
    line-height: 2rem;
  }

  h4 {
    font-size: 1.125rem;
    line-height: 2rem;
  }

  h5 {
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.5rem;
  }
}

// Global overrides for scrollbar
html {
  scroll-behavior: smooth !important;
}

::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5rem;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px inherit;
}
::-webkit-scrollbar-thumb {
  background-color: #999999;
  outline: 1px solid #999999;
  border-radius: 1rem;
}

// Utility classes
.bodyFixed {
  position: fixed;
}

.hideElement {
  height: 0;
  visibility: hidden;
  min-height: 0;
  margin-bottom: 0 !important;
}

// WCAG 2.1 SC 2.4.7 requirements : https://www.w3.org/WAI/WCAG21/Understanding/focus-visible.html
*:focus:not(:focus-visible) {
  /* Remove focus indication when a mouse is used */
  outline: none;
}
*:focus-visible {
  outline: 0.125rem solid #000000;
  outline-offset: 0.125rem;
}

.grecaptcha-badge {
  visibility: hidden;
}

// Start: Css to overlap featured trials component with hero and search component in CR.com
.container {
  position: relative;

  .overlap-featured-trials {
    margin-bottom: 450px;
  }

  .overlap-featured-trials ~ #featured-trial {
    position: absolute;
    top: 290px;
    height: 680px;
    width: 100%;
    background: #f7f8fa !important;

    .featured-trials-carousal {
      background: #f7f8fa !important;
    }
  }
}

@media screen and (min-width: 1300px) {
  .container {
    .overlap-featured-trials ~ #featured-trial {
      width: 1365px;
      margin-left: -650px;
      left: 50%;
      background: #f7f8fa !important;
    }
  }
}

@media screen and (max-width: 959px) {
  .container {
    .overlap-featured-trials {
      margin-bottom: 0px;
    }
  }
}
// End: Css to overlap featured trials component with hero and search component in CR.com

.sign-in-form {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.sign-in-form input {
  display: block;
  padding: 5px;
  border: 1px gray solid;
  border-radius: 3px;
  margin-top: 15px;
}
.sign-in-form h1 {
  font-size: 18px;
  text-align: center;
}
.sign-in-form form {
  padding: 50px 15px;
  border: 2px gray solid;
  border-radius: 3px;
}

#ot-sdk-btn-floating {
  bottom: 120px !important;
}

/* global.css or equivalent */
#_GTM_debug {
  display: none !important;
}